import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Button,
    Box
} from '@mui/material';
import { useState } from 'react';

import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { usePaymentsQuery } from '../../services/paymentsQuery';

export type TimeRegistrationProviderSelectProps = {
    selectedTimeRegistrationProvider: string;
    onSaveSuccess: () => void;
};

export const TimeRegistrationProviderSelect = (
    props: TimeRegistrationProviderSelectProps
) => {
    const [timeRegistrationProvider, setTimeRegistrationProvider] =
        useState<string>(props.selectedTimeRegistrationProvider);
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const onSaveClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .updateTimeRegistrationProvider({
                timeRegistrationProvider: timeRegistrationProvider
            })
            .then(() => {
                setAlert('Updated successfully', 'success');
                props.onSaveSuccess();
            })
            .catch(backendErrorHandler(undefined))
            .finally(() => {
                setLoading(false);
            });
    };

    const { getTimeRegistrationProvidersQuery } = usePaymentsQuery({
        enableGetTimeRegistrationProvidersQuery: true
    });

    return (
        <>
            <Typography variant="h5">
                Time registration provider selection
            </Typography>
            Please choose your company's time registration data provider:
            <Box>
                <FormControl>
                    <InputLabel></InputLabel>
                    <Select
                        variant="standard"
                        sx={{ minWidth: '200px' }}
                        value={timeRegistrationProvider}
                        label="Select time registration provider"
                        onChange={(e) =>
                            setTimeRegistrationProvider(e.target.value)
                        }
                    >
                        {getTimeRegistrationProvidersQuery?.data?.data?.providers?.map(
                            (timeRegistrationProvider) => (
                                <MenuItem
                                    key={timeRegistrationProvider.name}
                                    value={timeRegistrationProvider.name}
                                >
                                    {timeRegistrationProvider.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
                <Button
                    sx={{ marginTop: '1rem', marginLeft: '1rem' }}
                    variant="contained"
                    onClick={onSaveClick}
                >
                    Save
                </Button>
            </Box>
            <hr />
        </>
    );
};
