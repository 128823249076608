import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PaymentsAPIServiceV2 from './PaymentsServiceV2';
import { useEffect } from 'react';

const paymentsService = PaymentsAPIServiceV2.getInstance();

export const PAYMENT_QUERY_KEYS: { [key: string]: string } = {
    PLANDAY_COMPANY_INFO: 'getPlandayCompanyInfo',
    TIME_REGISTRATION_PROVIDERS: 'getTimeRegistrationProviders',
    EMPLOYEE_TYPES: 'getEmployeeTypes',
    DEPARTMENTS: 'getDepartments'
};

export const usePaymentsQuery = ({
    enableGetPlandayCompanyInfoQuery = false,
    enableGetTimeRegistrationProvidersQuery = false,
    enableGetEmployeeTypesQuery = false,
    enableGetDepartmentsQuery = false
}) => {
    const getPlandayCompanyInfoQuery = useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.PLANDAY_COMPANY_INFO],
        queryFn: paymentsService.getPlandayCompanyInfo,
        enabled: enableGetPlandayCompanyInfoQuery
    });

    const getTimeRegistrationProvidersQuery = useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.TIME_REGISTRATION_PROVIDERS],
        queryFn: paymentsService.getTimeRegistrationProviders,
        enabled: enableGetTimeRegistrationProvidersQuery
    });

    const getEmployeeTypes = useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.EMPLOYEE_TYPES],
        queryFn: paymentsService.getEmployeeTypes,
        enabled: enableGetEmployeeTypesQuery
    });

    const getEmployeeDepartments = useQuery({
        queryKey: [PAYMENT_QUERY_KEYS.DEPARTMENTS],
        queryFn: paymentsService.getEmployeeDepartments,
        enabled: enableGetDepartmentsQuery
    });

    const queryClient = useQueryClient();

    useEffect(() => {
        const queryKeys = [];
        if (enableGetPlandayCompanyInfoQuery) {
            queryKeys.push(PAYMENT_QUERY_KEYS.PLANDAY_COMPANY_INFO);
        }
        if (enableGetTimeRegistrationProvidersQuery) {
            queryKeys.push(PAYMENT_QUERY_KEYS.TIME_REGISTRATION_PROVIDERS);
        }
        if (enableGetEmployeeTypesQuery) {
            queryKeys.push(PAYMENT_QUERY_KEYS.EMPLOYEE_TYPES);
        }
        if (enableGetDepartmentsQuery) {
            queryKeys.push(PAYMENT_QUERY_KEYS.DEPARTMENTS);
        }

        queryClient.invalidateQueries({
            queryKey: queryKeys
        });
    }, [
        enableGetPlandayCompanyInfoQuery,
        enableGetTimeRegistrationProvidersQuery,
        enableGetEmployeeTypesQuery,
        enableGetDepartmentsQuery
    ]);

    return {
        getPlandayCompanyInfoQuery,
        getTimeRegistrationProvidersQuery,
        getEmployeeTypes,
        getEmployeeDepartments
    };
};
