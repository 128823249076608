import { ConsentType, PaymentProvider, SalaryProvider } from './payment';

export type Company = {
    companyId: string;
    createdAt: Date;
    name: string;
    currency: string;
    greetingText: string;
    theme: Theme;
    loginType: LoginType;
    endOfTrial: Date | null;
    timeRegistrationProvider: string;
    requiredConsentsAccepted: boolean;
    showReports: boolean;
};
import { CompanyDetailsState } from '../lib/slices/paymentsSlice';

export interface Theme {
    id: string;
    name: string;
    logoName: string;
    colors: any;
}

export type CompanyAccount = {
    companyId: string;
    name: string;
    balance: number;
    pendingTransactionsCount: number;
    currency: string;
};

export type CompanyAccountsResponse = {
    companyAccounts: CompanyAccount[];
};

export type CompanyWithAdmins = Company & {
    admins: AdminResponse[];
};

export const paymentSources = ['CompanyAccount', 'InstaPaidAccount'] as const;
export type PaymentSource = (typeof paymentSources)[number];

export const approvalTypes = ['Manual', 'Automatic'] as const;
export type ApprovalType = (typeof approvalTypes)[number];

export const lowBalanceAudiences = [
    'None',
    'AllAdmins',
    'PendingWithdrawalsEmails',
    'Custom'
] as const;
export type LowBalanceAudience = (typeof lowBalanceAudiences)[number];

export const getLowBalanceAudienceLabel = (
    lowBalanceAudience: LowBalanceAudience
) => {
    switch (lowBalanceAudience) {
        case 'AllAdmins':
            return 'All company admins';
        case 'Custom':
            return 'Custom list of email addresses';
        case 'PendingWithdrawalsEmails':
            return 'The same email which is notified about pending withdrawals';
        default:
            return lowBalanceAudience;
    }
};

export type CompaniesResponse = {
    companies: CompanyWithAdmins[];
};

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

export type AdminResponse = User & {
    id: string;
    isActive: boolean;
};

export type UserRole = 'User' | 'Admin' | 'SuperAdmin';

export type ResetUsersResponse = {
    disabledUsers: User[];
    resetUsers: User[];
};

export type UpdateUserRoleRequest = {
    userId: string;
    role: UserRole;
};

export type CurrentUser = User & {
    id: string;
    role: UserRole;
};

export type UserResponse = User & {
    id: string;
    isActive: boolean;
    isDisabled: boolean;
    role: UserRole;
};

export type CompanyWithUsers = Company & {
    users: Array<UserResponse>;
};

export enum LoginType {
    AzureB2C = 'AzureB2C',
    AzureAD = 'AzureAD'
}

export type UploadCompanyLogoRequest = {
    formData: FormData;
};

export type UpdateCompanyRequest = {
    greetingText: string;
    theme: string;
    defaultLanguage: string;
};

export type ActionType = 'Accepted' | 'Declined';

export type ConsentResponse = {
    consentId: string;
    consentType: ConsentType;
    companyId: string;
    performedByUserId: string;
    performedByEmail: string;
    performedAt: Date;
    actionType: ActionType;
    termsVersionId: string;
    termsVersionName: string | null;
    termsVersionUrl: string | null;
};

export type GetConsentsResponse = {
    consents: ConsentResponse[];
};

export type UpdateConsentsRequest = {
    commercialConsented: boolean;
    dataConsented: boolean;
    newsletterAndUpdatesConsented: boolean | null;
    commercialTermsVersion: string | null;
    dataTermsVersion: string | null;
};

export type UpdateCompanyAuthSettingsRequest = {
    tenantId?: string;
    loginType?: LoginType;
};

export type CreateCompanyRequest = {
    name: string;
    currency: string;
    country: string;
    tenantId: string;
    loginType: LoginType;
    salaryProvider: SalaryProvider;
    timeRegistrationProvider: string;
    pricePerUser: number;
    endOfTrial: Date;
    paymentProvider: PaymentProvider;
    paymentSource: PaymentSource;
    approvalType: ApprovalType;
    lowBalanceNotificationThreshold: number | null;
    lowBalanceAudience: LowBalanceAudience;
    lowBalanceEmail: string | null;
    admin: User;
};

export type CreateUserRequest = User & {
    isActive: boolean;
    isAdmin: boolean;
};

export type UpdateUserRequest = {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
};

export type ChangeUsersStatusRequest = {
    userIds: string[];
};

export type Language = {
    code: string;
    name: string;
};

export type LanguagesResponse = {
    languages: Language[];
};

export interface CompanySettingProps {
    currentCompany?: any;
    companyDetails?: CompanyDetailsState;
    currentUser?: any;
    flag?: boolean;
    reloadDetails?: () => void;
}

export type UserRoles = 'User' | 'Admin' | 'SuperAdmin' | 'GroupAdmin';

export enum UserRolesData {
    // User = 0,
    Admin = 1
    // SuperAdmin = 2,
    // GroupAdmin = 3
}

export type UserRolesType = {
    id: string;
    name: UserRoles;
    isUsersSyncEnabled: boolean;
};

export type UserRolesResponse = {
    userRole: UserRolesType[];
};

export type ActionsType = 'add' | 'delete';

export type AdminCompanyIds = {
    companyId: string;
    action: string;
};

export type CreateUsersInMultiCompaniesRequest = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isActive: boolean;
    role: string;
    companies: AdminCompanyIds[];
};

export enum UserType {
    Normal = 'Normal',
    Admin = 'Admin'
}
